import React, { useEffect, useState } from 'react'
import body from '@/assets/images/body-area.png'
import bodyWebp from '@/assets/images/body-area.webp'
import { Answers } from '@/types/types'
import Image from '@/components/Image/Image'

interface BodyAreasProps {
  answers: Answers
  step: number
  question: number
}
export function BodyAreas(props: BodyAreasProps) {
  const [activeAreas, setActiveAreas] = useState([['']])

  useEffect(() => {
    console.log(props.answers[props.step][props.question])

    if (props.answers[props.step][props.question]) {
      setActiveAreas(Object.values(props.answers[props.step][props.question]))
    }
  }, [props.answers, props.step, props.question])
  return (
    <div className="mt-10 body-areas">
      <div className="absolute right-0 -top-10">
        <div>
          <Image
            width="130"
            height="461"
            src={body}
            webp={bodyWebp}
            alt="Body area"
            className="bg-white"
          />
        </div>
        <svg
          width="179"
          height="335"
          viewBox="0 0 179 335"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className="absolute right-0 top-71"
        >
          <g
            className={`${activeAreas[0].includes('Toned arms') ? 'opacity-100' : 'opacity-0'} mix-blend-overlay transition-all duration-300`}
          >
            <path
              d="M155.852 3.21493C157.954 0.535821 177.948 0 177.948 0L179 47.6881L177.948 95.3762C177.948 95.3762 170.287 97.19 165.322 96.9836C161.576 96.8279 155.853 95.3762 155.853 95.3762V84.1239L154.801 75.015V48.7597L154.275 25.7194L154.012 14.1993L154.011 11.5078C154.011 11.5078 153.75 5.89403 155.852 3.21493Z"
              fill="url(#paint0_linear_2410_3198)"
            />
          </g>
          <g
            className={`${activeAreas[0].includes('Flat belly') ? 'opacity-100' : 'opacity-0'} mix-blend-overlay transition-all duration-300`}
          >
            <path
              d="M79.0602 76.6818C79.5908 75.6091 86.5188 74 98.2396 74C109.96 74 129.672 76.6818 129.672 76.6818L131.271 93.3089L135 104.572C135 104.572 112.624 112.082 96.6413 115.836C80.6585 119.591 79.0552 113.154 79.0602 112.082C79.0652 111.009 82.8487 102.427 82.2568 97.0635C81.6648 91.6999 78.5296 77.7545 79.0602 76.6818Z"
              fill="url(#paint1_linear_2410_3198)"
            />
          </g>
          <g
            className={`${activeAreas[0].includes('Round butt') ? 'opacity-100' : 'opacity-0'} mix-blend-overlay transition-all duration-300`}
          >
            <path
              d="M129.478 153.11C123.632 134.751 135.335 104.122 135.335 104.122L151.701 120.955L158.413 144.614L156.107 156.307C156.107 156.307 152.38 161.099 150.782 165.36C150.174 166.983 150.245 166.955 149.18 169.617C149.18 174.41 148.652 176.008 148.652 176.008L148.12 180.8C148.12 180.8 133.434 165.531 129.478 153.11Z"
              fill="url(#paint2_linear_2410_3198)"
            />
          </g>
          <g
            className={`${activeAreas[0].includes('Round butt') ? 'opacity-100' : 'opacity-0'} mix-blend-overlay transition-all duration-300`}
          >
            <path
              d="M129.478 153.11C123.632 134.751 135.335 104.122 135.335 104.122L151.701 120.955L158.413 144.614L156.107 156.307C156.107 156.307 152.38 161.099 150.782 165.36C150.174 166.983 150.245 166.955 149.18 169.617C149.18 174.41 148.652 176.008 148.652 176.008L148.12 180.8C148.12 180.8 133.434 165.531 129.478 153.11Z"
              fill="url(#paint3_linear_2410_3198)"
            />
          </g>
          <g
            className={`${activeAreas[0].includes('Slim legs') || activeAreas[0].includes('Fully body slimming') ? 'opacity-100' : 'opacity-0'} mix-blend-overlay transition-all duration-300`}
          >
            <path
              d="M75 170C75 170 106.814 178.488 107.874 180.61C108.935 182.731 110.903 200.82 114.237 214.56C117.546 228.193 124.312 249.041 124.312 249.041L131.735 285.113L132 309.78L125.372 332.326C125.372 332.326 120.684 334.742 117.419 334.978C114.056 335.22 108.935 333.386 108.935 333.386L97.8 303.68L96.2093 259.12L76.5907 197.054L75 170Z"
              fill="url(#paint4_linear_2410_3198)"
            />
          </g>
          <g
            className={`${activeAreas[0].includes('Slim legs') || activeAreas[0].includes('Fully body slimming') ? 'opacity-100' : 'opacity-0'} mix-blend-overlay transition-all duration-300`}
          >
            <path
              d="M75 170C75 170 106.814 178.488 107.874 180.61C108.935 182.731 110.903 200.82 114.237 214.56C117.546 228.193 124.312 249.041 124.312 249.041L131.735 285.113L132 309.78L125.372 332.326C125.372 332.326 120.684 334.742 117.419 334.978C114.056 335.22 108.935 333.386 108.935 333.386L97.8 303.68L96.2093 259.12L76.5907 197.054L75 170Z"
              fill="url(#paint5_linear_2410_3198)"
            />
          </g>
          <path
            d="M1 1.5C0.723858 1.5 0.5 1.72386 0.5 2C0.5 2.27614 0.723858 2.5 1 2.5V1.5ZM30.5 2L30.6686 1.52929L30.5869 1.5H30.5V2ZM1 2.5H30.5V1.5H1V2.5ZM30.3314 2.47071L164.331 50.4707L164.669 49.5293L30.6686 1.52929L30.3314 2.47071Z"
            className={`${activeAreas[0].includes('Toned arms') ? 'fill-green' : 'fill-checkBorder'} transition-all duration-300`}
          />
          <circle
            cx="165"
            cy="50"
            r="2.5"
            stroke="white"
            className={`${activeAreas[0].includes('Toned arms') ? 'fill-green' : 'fill-checkBorder'} transition-all duration-300`}
          />
          <path
            d="M1 74.5C0.723858 74.5 0.5 74.7239 0.5 75C0.5 75.2761 0.723858 75.5 1 75.5V74.5ZM30 75L30.1417 74.5205L30.0723 74.5H30V75ZM1 75.5H30V74.5H1V75.5ZM29.8583 75.4795L95.8583 94.9795L96.1417 94.0205L30.1417 74.5205L29.8583 75.4795Z"
            className={`${activeAreas[0].includes('Flat belly') ? 'fill-green' : 'fill-checkBorder'} transition-all duration-300`}
          />
          <circle
            cx="97"
            cy="95"
            r="2.5"
            fill="#13D16A"
            stroke="white"
            className={`${activeAreas[0].includes('Flat belly') ? 'fill-green' : 'fill-checkBorder'} transition-all duration-300`}
          />
          <path
            d="M1 148H30H142"
            stroke="#13D16A"
            className={`${activeAreas[0].includes('Round butt') ? 'stroke-green' : 'stroke-checkBorder'} transition-all duration-300`}
          />
          <circle
            cx="142"
            cy="148"
            r="2.5"
            fill="#13D16A"
            stroke="white"
            className={`${activeAreas[0].includes('Round butt') ? 'fill-green' : 'fill-checkBorder'} transition-all duration-300`}
          />
          <path
            d="M1 220.5C0.723858 220.5 0.5 220.724 0.5 221C0.5 221.276 0.723858 221.5 1 221.5V220.5ZM30 221L30.0702 220.505L30.0353 220.5H30V221ZM1 221.5H30V220.5H1V221.5ZM29.9298 221.495L103.93 231.995L104.07 231.005L30.0702 220.505L29.9298 221.495Z"
            className={`${activeAreas[0].includes('Slim legs') ? 'fill-green' : 'fill-checkBorder'} transition-all duration-300`}
          />
          <circle
            cx="104"
            cy="231"
            r="2.5"
            fill="#13D16A"
            stroke="white"
            className={`${activeAreas[0].includes('Slim legs') ? 'fill-green' : 'fill-checkBorder'} transition-all duration-300`}
          />
          <path
            d="M1 293.5C0.723858 293.5 0.5 293.724 0.5 294C0.5 294.276 0.723858 294.5 1 294.5V293.5ZM30 294L30.0699 293.505L30.0351 293.5H30V294ZM1 294.5H30V293.5H1V294.5ZM29.9301 294.495L114.93 306.495L115.07 305.505L30.0699 293.505L29.9301 294.495Z"
            className={`${activeAreas[0].includes('Fully body slimming') ? 'fill-green' : 'fill-checkBorder'} transition-all duration-300`}
          />
          <circle
            cx="115"
            cy="306"
            r="2.5"
            fill="#13D16A"
            stroke="white"
            className={`${activeAreas[0].includes('Fully body slimming') ? 'fill-green' : 'fill-checkBorder'} transition-all duration-300`}
          />
          <defs>
            <linearGradient
              id="paint0_linear_2410_3198"
              x1="166.5"
              y1="0"
              x2="166.5"
              y2="97"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#F2BF09" />
              <stop offset="1" stopColor="#FF0000" stopOpacity="0.96" />
            </linearGradient>
            <linearGradient
              id="paint1_linear_2410_3198"
              x1="107.028"
              y1="74"
              x2="107.028"
              y2="116.887"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#F2BF09" />
              <stop offset="1" stopColor="#FF0000" stopOpacity="0.96" />
            </linearGradient>
            <linearGradient
              id="paint2_linear_2410_3198"
              x1="137.099"
              y1="124.977"
              x2="151.358"
              y2="175.24"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#F2BF09" />
              <stop offset="1" stopColor="#FF0000" stopOpacity="0.96" />
            </linearGradient>
            <linearGradient
              id="paint3_linear_2410_3198"
              x1="137.099"
              y1="124.977"
              x2="151.358"
              y2="175.24"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#F2BF09" />
              <stop offset="1" stopColor="#FF0000" stopOpacity="0.96" />
            </linearGradient>
            <linearGradient
              id="paint4_linear_2410_3198"
              x1="103.385"
              y1="213.879"
              x2="103.385"
              y2="325.464"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#F2BF09" />
              <stop offset="1" stopColor="#FF0000" stopOpacity="0.96" />
            </linearGradient>
            <linearGradient
              id="paint5_linear_2410_3198"
              x1="103.385"
              y1="213.879"
              x2="103.385"
              y2="325.464"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#F2BF09" />
              <stop offset="1" stopColor="#FF0000" stopOpacity="0.96" />
            </linearGradient>
          </defs>
        </svg>
      </div>
    </div>
  )
}
