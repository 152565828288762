import React from 'react'
import { Button } from '@/components/Button/Button'
import img from '@/assets/images/modal-graph.jpg'
import imgWebp from '@/assets/images/modal-graph.webp'
import { trackDiscount } from '@/helpers/facebookPixelEvents'
import Image from '@/components/Image/Image'

interface Percent45OverlayProps {
  onCloseOverlay: () => void
  isShow: boolean
  betterme?: boolean
}

export default function Percent45Overlay(props: Percent45OverlayProps) {
  const planName = 'Wall Pilates Plan'

  const handleClick = () => {
    props.onCloseOverlay()
    trackDiscount('discount_71')
  }

  return (
    <>
      <div className={'absolute top-0 left-0 w-full h-full blur-sm'} onClick={handleClick}></div>
      <div
        className={`${props.isShow ? 'active' : ''} border overflow-x-hidden overflow-y-auto max-h-[95%] border-borderGrey show-popup bg-white z-10 relative px-4 pb-20 rounded-t-3xl transition-all duration-500 `}
      >
        <div onTouchEnd={handleClick} className="sticky min-w-image2 -ml-4 top-0 bg-white py-4">
          <span className="w-16 h-0.5 bg-borderGrey block mx-auto"></span>
        </div>
        <h2 className="pt-0 mb-2 text-xl3 text-center">
          Did you <span className="text-green2">know?</span>
        </h2>
        <p className="text-xl font-bold text-center">
          <span className="text-green2">65%</span> of users who started their{' '}
          <span className="text-green2">{planName} with Fit4Me</span> advanced in their goals within
          the <span className="text-green2">first month*</span>
        </p>
        <Image
          className="max-w-[80%] mt-2 mx-auto"
          src={img}
          webp={imgWebp}
          width={390}
          height={373}
          alt="Chart image"
        />
        <p className="text-dark text-sm opacity-50 mt-2">
          * Based on the data of users who log their progress in the app
        </p>
        <p className="mt-4 py-2 px-2 font-bold text-center border-borderGrey border bg-goal rounded-3xl">
          We want you to find success so we are offering the{' '}
          <span className="text-green2">additional discount</span> on your{' '}
          <span className="text-green2">{planName}</span>
        </p>
        <div className="fixed w-[calc(100%-32px)] bottom-4 items-end mt-4 mx-auto">
          <Button onClick={handleClick} className="btn-large">
            got it
          </Button>
        </div>
      </div>
    </>
  )
}
