import React from 'react'
import { Button } from '@/components/Button/Button'
import { trackDiscount } from '@/helpers/facebookPixelEvents'

interface FreeTrialOverlayProps {
  onCloseOverlay: () => void
  isShow: boolean
}

export default function FreeTrialOverlay(props: FreeTrialOverlayProps) {
  const handleClick = () => {
    props.onCloseOverlay()
    trackDiscount('activate_trial')
  }

  return (
    <div className="fixed top-0 left-1/2 z-[100] backdrop-blur-md -translate-x-1/2 h-full max-w-content w-full flex items-end">
      <div className={'absolute top-0 left-0 w-full h-full blur-sm'} onClick={handleClick}></div>
      <div
        className={`${props.isShow ? 'active' : ''} border overflow-x-hidden overflow-y-auto max-h-[95%] border-borderGrey show-popup bg-white z-10 relative px-4 pb-20 rounded-t-3xl transition-all duration-500 `}
      >
        <div onTouchEnd={handleClick} className="sticky min-w-image2 -ml-4 top-0 bg-white py-4">
          <span className="w-16 h-0.5 bg-borderGrey block mx-auto"></span>
        </div>
        <h2 className="pt-0 mb-4 text-xl3 mt-4 text-center">
          Still <span className="text-green2">not sure?</span>
        </h2>
        <p className="text-xl font-semibold text-center">
          <span className="text-green2">Based on your input,</span> we&#39;ve designed a customized{' '}
          <span className="text-green2">7-day trial</span> experience to help you determine if
          we&#39;re the right fit for you!
        </p>
        <div className="mt-6 py-5 px-3 flex items-start font-semibold text-xl border-borderGrey border bg-goal rounded-3xl">
          <span className="p-1 bg-green mr-5 rounded-full block">
            <svg
              width="21"
              height="21"
              viewBox="0 0 21 21"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M7.30625 15.3125H4.8125C4.32925 15.3125 3.9375 14.9207 3.9375 14.4375V10.0625C3.9375 9.57925 4.32925 9.1875 4.8125 9.1875H7.30625C7.37874 9.1875 7.4375 9.24626 7.4375 9.31875V15.1813C7.4375 15.2537 7.37874 15.3125 7.30625 15.3125Z"
                stroke="white"
                strokeWidth="0.875"
                strokeLinecap="round"
              />
              <path
                d="M7.4375 10.0625L9.09512 6.74725C9.15587 6.62575 9.1875 6.49178 9.1875 6.35594V4.8125C9.1875 4.32925 9.57925 3.9375 10.0625 3.9375V3.9375C11.029 3.9375 11.8125 4.721 11.8125 5.6875V9.1875"
                stroke="white"
                strokeWidth="0.875"
                strokeLinecap="round"
              />
              <path
                d="M10.0625 9.1875H15.3101C15.8932 9.1875 16.3132 9.74725 16.1501 10.3072L14.6211 15.5572C14.5123 15.9307 14.17 16.1875 13.781 16.1875H10.4249C10.1929 16.1875 9.97031 16.0953 9.80622 15.9312L9.44378 15.5688C9.27969 15.4047 9.05713 15.3125 8.82506 15.3125H7.4375"
                stroke="white"
                strokeWidth="0.875"
                strokeLinecap="round"
              />
            </svg>
          </span>
          <p>
            We&#39;re convinced this trial could have a significant impact on your life, but
            it&#39;s normal to have doubts.{' '}
            <span className="text-green2">So, why not give it a whirl for just a week?</span>
          </p>
        </div>
        <p className="text-xl mt-6 font-semibold text-center">
          <span className="text-green2">Take our 7-day trial for a spin </span> and decide if you
          want to stick with us afterwards. You&#39;re welcome to cancel anytime at no charge.
        </p>
        <div className="fixed w-[calc(100%-32px)] bottom-4 items-end mt-4 mx-auto">
          <Button onClick={handleClick} className="btn-large">
            START 7-DAY TRIAL
          </Button>
        </div>
      </div>
    </div>
  )
}
