import { BaseHttpServices } from '@/services/base-http-services'
import { getPaymentPlansApiErrorMessage } from '@/app/api/payment-plan-api/payment-plan-api-error'

interface PaymentPlanApiInterface {
  fing: () => Promise<any>
}

export class PaymentPlanApiService implements PaymentPlanApiInterface {
  private readonly http: BaseHttpServices

  constructor(httpService: BaseHttpServices) {
    this.http = httpService
    this.http.getErrorMessage = (message) => getPaymentPlansApiErrorMessage(message)
  }

  fing = async () => {
    const resp = await this.http.get('/payment-plan')

    return resp.data
  }
}

export const PaymentPlanApi = new PaymentPlanApiService(new BaseHttpServices())
