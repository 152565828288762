import React, { useEffect, useState } from 'react'

export default function PlansTimer() {
  const [endTime, setEndTime] = useState<Date | null>(null)
  const [timeDifference, setTimeDifference] = useState<number | null>(null)

  const startTimer = () => {
    if (endTime) {
      const intervalId = setInterval(() => {
        const currentTime = new Date()
        const difference = endTime.getTime() - currentTime.getTime()
        if (difference <= 0) {
          clearInterval(intervalId)
          setTimeDifference(0)
        } else {
          setTimeDifference(difference)
        }
      }, 1000)
      return () => clearInterval(intervalId)
    }
  }

  useEffect(() => {
    const storedEndTime = localStorage.getItem('endTimer')
    if (storedEndTime) {
      setEndTime(new Date(storedEndTime))
    }
  }, [])

  useEffect(() => {
    if (endTime) {
      const currentTime = new Date()
      const difference = endTime.getTime() - currentTime.getTime()
      if (difference > 0) {
        startTimer()
      } else {
        setTimeDifference(0)
      }
    }
  }, [endTime])

  const minutes = Math.floor((timeDifference ?? 0) / (1000 * 60))
  const seconds = Math.floor(((timeDifference ?? 0) % (1000 * 60)) / 1000)

  return (
    <div className=" w-full text-center bg-plan px-4 py-[6px] rounded-r12 flex items-center justify-center">
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10 0C4.47717 0 0 4.47715 0 9.99997C0 15.5228 4.47716 20 10 20C15.5229 20 20 15.5228 20 9.99997C20 4.47715 15.5228 0 10 0ZM1.43444 9.99997C1.43444 5.26936 5.26939 1.43442 10 1.43442C14.7306 1.43442 18.5656 5.26936 18.5656 9.99997C18.5656 14.7305 14.7306 18.5656 10 18.5656C5.26939 18.5656 1.43444 14.7305 1.43444 9.99997ZM10.7548 5.47045C10.7548 5.0535 10.4168 4.71549 9.99988 4.71549C9.58293 4.71549 9.24492 5.0535 9.24492 5.47045V10.0002C9.24492 10.2005 9.32446 10.3925 9.46605 10.5341L12.4859 13.5539C12.7807 13.8487 13.2587 13.8487 13.5536 13.5539C13.8484 13.2591 13.8484 12.7811 13.5536 12.4862L10.7548 9.68753V5.47045Z"
          fill="white"
        />
      </svg>

      <p className="text-md2 text-white font-bold leading-6 ml-2 flex">
        This offer ends in
        <span className="w-[50px] text-left px-1.5">
          {minutes < 10 ? '0' : ''}
          {minutes || '0'}:{seconds < 10 ? '0' : ''}
          {seconds || '0'}
        </span>
        min
      </p>
    </div>
  )
}
