import { getValue } from '@/helpers/getObjectValue'
import { Answers } from '@/types/types'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

interface BMIProps {
  active: boolean
  answers: Answers
}
export function BMI(props: BMIProps) {
  const { t } = useTranslation()
  const [bmi, setBMI] = useState(15)
  const [percent, setPercent] = useState('7%')
  useEffect(() => {
    if (props.active) {
      const heightProps = getValue('human-height', props.answers)
      const weightProps = getValue('current-weight', props.answers)
      if (heightProps && weightProps) {
        const tall =
          heightProps && heightProps[1] === 'FT' ? +heightProps[0] * 30.48 : +heightProps[0]
        const weight =
          weightProps && weightProps[1] === 'lbs' ? +weightProps[0] * 0.4536 : +weightProps[0]
        const bmi = weight / ((tall / 100) * (tall / 100))
        setBMI(bmi)
        if (bmi > 30) {
          setPercent('93%')
        } else if (bmi < 15) {
          setPercent('7%')
        } else {
          setPercent((bmi - 16) * 5.4 + 14 + '%')
        }
      }
    }
  }, [props.active])
  return (
    <div className="p-4 rounded-r12 border border-borderInput bg-lightGray">
      {props.active}
      <div className="flex items-center justify-between">
        <h6 className="text-base font-extrabold text-dark">{t('Body Mass Index (BMI)')}</h6>
        <div className="bg-olive text-white font-extrabold text-xs2 py-1 px-2 rounded-md scale-60">
          {t('Normal')} - 21.5
        </div>
      </div>
      <div className="">
        <div className="relative pt-14">
          <div
            style={{ left: percent }}
            className={`${props.active ? 'scale-100 bmi-transition' : 'scale-50'} mb-6 absolute -bottom-1.5 -translate-x-1/2  after:-bottom-6 after:absolute after:content-['']  after:left-1/2 after:-translate-x-1/2 after:w-4 after:h-4 after:rounded-full  after:bg-olive after:border-2 after:border-white text-center`}
          >
            <span className="block relative -left-px py-1 px-1.5 rounded-lg bg-black text-white font-extrabold text-xs2 before:absolute before:content-['']  before:left-1/2 before:-translate-x-1/2 before:-bottom-0.5 before:w-2 before:h-2 before:bg-black before:rotate-45 whitespace-nowrap">
              {t('You')} — {bmi.toFixed(1)}
            </span>
          </div>
          <div className="rounded-r2 h-1 w-full bg-progressLinear"></div>
        </div>
        <div className="flex items-center justify-between mt-2">
          <span className="block text-xs2 font-semibold text-dark">{t('Underweight')}</span>
          <span className="block text-xs2 font-semibold text-dark">{t('Normal')}</span>
          <span className="block text-xs2 font-semibold text-dark">{t('Overweight')}</span>
          <span className="block text-xs2 font-semibold text-dark">{t('Obese')}</span>
        </div>
      </div>
    </div>
  )
}
