import React, { useEffect, useState } from 'react'
import { Answer, Answers } from '@/types/types'
import { useTranslation } from 'react-i18next'
import { ProgressStep } from './ProgressStep'
import { getValue } from '@/helpers/getObjectValue'

interface ProgressProps {
  active: boolean
  src: string
  answers: Answers
  onAnswer: (answer: Answer, step?: number) => void
}

export default function ProgressSteps(props: ProgressProps) {
  const progress = getValue('progress-step', props.answers)
  const [isActive, setIsActive] = useState(false)
  const [isActive2, setIsActive2] = useState(false)
  const [isActive3, setIsActive3] = useState(false)
  // const [isActive4, setIsActive4] = useState(false)
  const [animationData, setAnimationData] = useState<any>(undefined)
  const [progressStep, setProgressStep] = useState(progress ? 2 : 1)
  const [showPopup, seShowPopup] = useState(false)
  const { t } = useTranslation()
  useEffect(() => {
    if (props?.src && !animationData) {
      fetch(props?.src)
        .then((response) => response.json())
        .then((data) => setAnimationData(data))
        .catch((error) => console.error('Error fetching animation data:', error))
    }
  }, [])
  useEffect(() => {
    if (props.active && !progress) {
      setProgressStep(1)
      seShowPopup(false)
      setIsActive(true)
      setTimeout(() => {
        seShowPopup(true)
      }, 3000)
    }
  }, [props.active])
  const closePopup = () => {
    if (!progress) {
      setProgressStep((progressStep ?? 1) + 1)
      seShowPopup(false)
      if (progressStep === 1) {
        setIsActive2(true)
        setTimeout(() => {
          seShowPopup(true)
        }, 3000)
      }
      if (progressStep === 2) {
        setIsActive3(true)
        setTimeout(() => {
          // setIsActive4(true)
          props.onAnswer({ 'progress-step': ['progress-step'] })
        }, 3000)
        // setTimeout(() => {
        //   props.onAnswer({ 'progress-step': ['progress-step'] })
        // }, 6000)
      }
    }
  }
  return (
    <div>
      <div className="progress-bar p-4 bg-[#FAFAFA] rounded-r12 border border-borderInput">
        <ProgressStep answers={props.answers} title="Fitness Level Analysis" active={isActive} />
        <ProgressStep answers={props.answers} title="Lifestyle and Activity" active={isActive2} />
        <ProgressStep answers={props.answers} title="Body Analysis" active={isActive3} />
        {/* <ProgressStep answers={props.answers} title="Generating Your Plan" active={isActive4} /> */}
      </div>
      <div
        className={`${showPopup && progressStep === 1 ? 'opacity-100 visible' : 'opacity-0 invisible'} transition-all fixed z-50 bg-lightBlue top-0 left-0 flex items-center justify-center w-full h-screen backdrop-blur-sm p-9`}
      >
        <div className="bg-white text-center border-2 border-borderInput rounded-r12 pt-14 pb-10 px-6 shadow-popup">
          <h2 className="text-xl3 font-extrabold p-0 m-0 mb-10">
            {t('Do you')} <mark>{t('give up')}</mark> {t('when exercises become too')}{' '}
            <mark>{t('hard or boring')}?</mark>
          </h2>
          <div
            onClick={closePopup}
            className="mx-4 text-xl2 flex items-center justify-center bg-lightGray rounded-r12 border border-borderInput p-4 font-bold active:scale-95 transition-all cursor-pointer"
          >
            {t('YES')}
          </div>
          <div
            onClick={closePopup}
            className="mx-4 text-xl2 flex items-center justify-center bg-lightGray rounded-r12 border border-borderInput p-4 font-bold active:scale-95 transition-all cursor-pointer mt-3"
          >
            {t('NO')}
          </div>
        </div>
      </div>
      <div
        className={`${showPopup && progressStep === 2 ? 'opacity-100 visible' : 'opacity-0 invisible'} transition-all fixed z-50 bg-lightBlue  top-0 left-0 flex  items-center justify-center w-full h-screen backdrop-blur-sm p-9`}
      >
        <div className="bg-white text-center border-2 border-borderInput rounded-r12 pt-14 pb-10 px-6 shadow-popup">
          <h2 className="text-xl3 font-extrabold p-0 m-0 mb-10">
            {t('Are you a')} <mark>{t('morning lark')}</mark> {t('or')}{' '}
            <mark>{t('night owl?')}</mark>
          </h2>
          <div
            onClick={closePopup}
            className="mx-4 text-xl2 flex items-center justify-center bg-lightGray rounded-r12 border border-borderInput p-4 font-bold active:scale-95 transition-all cursor-pointer"
          >
            {t('Morning Lark')}
          </div>
          <div
            onClick={closePopup}
            className="mx-4 text-xl2 flex items-center justify-center bg-lightGray rounded-r12 border border-borderInput p-4 font-bold active:scale-95 transition-all cursor-pointer mt-3"
          >
            {t('Night Owl')}
          </div>
          <div
            onClick={closePopup}
            className="mx-4 text-xl2 flex items-center justify-center bg-lightGray rounded-r12 border border-borderInput p-4 font-bold active:scale-95 transition-all cursor-pointer mt-3"
          >
            {t("I'm not sure")}
          </div>
        </div>
      </div>
    </div>
  )
}
