import { addPathPrefix } from '@/helpers/getPath'
import React, { ComponentProps } from 'react'
import ImageWebp from 'react-image-webp'
import { isWebpSupported } from 'react-image-webp/dist/utils'

interface ImageProps extends ComponentProps<typeof ImageWebp> {}

export default function Image(props: ImageProps) {
  const { webp, src, ...args } = props

  const srcWithPrefix = webp && isWebpSupported() ? addPathPrefix(webp) : addPathPrefix(src)

  return <img src={srcWithPrefix} {...args} />
}
