import React from 'react'
import { TestimonialsType } from '@/types/types'
import Image from '@/components/Image/Image'
import stars from '@/assets/images/stars.svg'

export default function Testimonials(props: TestimonialsType) {
  return (
    <>
      {!props.mode && (
        <div className=" rounded-r12 overflow-hidden border border-borderInput bg-lightGray p-4 mt-2">
          <div className="flex items-center">
            {props.src && !props.srcWebp && (
              <img
                className="min-w-11 max-w-11 h-11 rounded-full overflow-hidden object-cover mr-2"
                width="44"
                height="44"
                src={props.src}
                alt="reviw image"
              />
            )}
            {props.srcWebp && (
              <Image
                className="min-w-11 max-w-11 h-11 rounded-full overflow-hidden object-cover mr-2"
                width="44"
                height="44"
                src={props.src}
                webp={props.srcWebp}
                alt="reviw image"
              />
            )}
            <div className="w-full">
              <div className="flex justify-between w-full">
                <h3 className="font-extrabold">{props.name}</h3>
                <img
                  className=" max-w-full w-auto block mr-0 ml-auto"
                  width={106}
                  height={20}
                  alt="Stars"
                  src={stars}
                />
              </div>
              <p className="font-semibold text-xs2 text-dark opacity-65 mt-1">{props.title}</p>
            </div>
          </div>
          <blockquote className="mt-4 text-dark font-medium text-md">{props.quote}</blockquote>
        </div>
      )}
      {props.mode && (
        <div className="rounded-r16 overflow-hidden  bg-grayB p-4 mt-2">
          <div className="">
            <div className="flex gap-0.5 mb-6">
              <svg
                viewBox="0 0 20 20"
                width="1em"
                height="1em"
                data-icon="StarIcon"
                className="svg-icon-module_svgRoot__VmjSs svg-icon-module_iconSizeS__BM0Vs svg-icon-module_colorModeDefault__tEprC"
              >
                <path d="M0 0h20v20H0z" fill="#00b67a"></path>
                <path
                  d="M13.05 12.864 14.326 17l-4.325-3.305 3.047-.83ZM17 8.35h-5.346L10.003 3 8.346 8.35 3 8.344l4.33 3.311L5.673 17l4.33-3.305 2.673-2.04L17 8.35Z"
                  fill="white"
                ></path>
              </svg>
              <svg
                viewBox="0 0 20 20"
                width="1em"
                height="1em"
                data-icon="StarIcon"
                className="svg-icon-module_svgRoot__VmjSs svg-icon-module_iconSizeS__BM0Vs svg-icon-module_colorModeDefault__tEprC"
              >
                <path d="M0 0h20v20H0z" fill="#00b67a"></path>
                <path
                  d="M13.05 12.864 14.326 17l-4.325-3.305 3.047-.83ZM17 8.35h-5.346L10.003 3 8.346 8.35 3 8.344l4.33 3.311L5.673 17l4.33-3.305 2.673-2.04L17 8.35Z"
                  fill="white"
                ></path>
              </svg>
              <svg
                viewBox="0 0 20 20"
                width="1em"
                height="1em"
                data-icon="StarIcon"
                className="svg-icon-module_svgRoot__VmjSs svg-icon-module_iconSizeS__BM0Vs svg-icon-module_colorModeDefault__tEprC"
              >
                <path d="M0 0h20v20H0z" fill="#00b67a"></path>
                <path
                  d="M13.05 12.864 14.326 17l-4.325-3.305 3.047-.83ZM17 8.35h-5.346L10.003 3 8.346 8.35 3 8.344l4.33 3.311L5.673 17l4.33-3.305 2.673-2.04L17 8.35Z"
                  fill="white"
                ></path>
              </svg>
              <svg
                viewBox="0 0 20 20"
                width="1em"
                height="1em"
                data-icon="StarIcon"
                className="svg-icon-module_svgRoot__VmjSs svg-icon-module_iconSizeS__BM0Vs svg-icon-module_colorModeDefault__tEprC"
              >
                <path d="M0 0h20v20H0z" fill="#00b67a"></path>
                <path
                  d="M13.05 12.864 14.326 17l-4.325-3.305 3.047-.83ZM17 8.35h-5.346L10.003 3 8.346 8.35 3 8.344l4.33 3.311L5.673 17l4.33-3.305 2.673-2.04L17 8.35Z"
                  fill="white"
                ></path>
              </svg>
              <svg
                viewBox="0 0 20 20"
                width="1em"
                height="1em"
                data-icon="StarIcon"
                className="svg-icon-module_svgRoot__VmjSs svg-icon-module_iconSizeS__BM0Vs svg-icon-module_colorModeDefault__tEprC"
              >
                <path d="M0 0h20v20H0z" fill="#00b67a"></path>
                <path
                  d="M13.05 12.864 14.326 17l-4.325-3.305 3.047-.83ZM17 8.35h-5.346L10.003 3 8.346 8.35 3 8.344l4.33 3.311L5.673 17l4.33-3.305 2.673-2.04L17 8.35Z"
                  fill="white"
                ></path>
              </svg>
            </div>
            <div className="flex items-center justify-between">
              <p className="text-md font-medium">{props.title}</p>
              <h3 className="text-md  font-medium">{props.name}</h3>
            </div>
          </div>
          <blockquote className="mt-2 text-dark  text-base">{props.quote}</blockquote>
        </div>
      )}
    </>
  )
}
