import { useLocation, useNavigate } from 'react-router-dom'
import Layout from './Layout'
import React, { useEffect, useState } from 'react'
import logo from '@/assets/images/logo.svg'
import { useTranslation } from 'react-i18next'

export function ConfirmEmail() {
  const { t } = useTranslation()
  const location = useLocation()
  const navigate = useNavigate()
  const [queryParams, setQueryParams] = useState<{ [key: string]: string }>({})

  const verifyAccount = async (email: string) => {
    try {
      await fetch(`${process.env.REACT_APP_CREATE_ACCOUNT_ENDPOINT}/api/v1/auth/email/verify`, {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ email }),
      })
    } catch (e) {
      console.log(e)
    }
  }

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search)
    const params: { [key: string]: string } = {}
    searchParams.forEach((value, key) => {
      params[key] = value
    })
    setQueryParams(params)
  }, [location.search])

  useEffect(() => {
    if (queryParams.email) {
      verifyAccount(queryParams.email)
    }
  }, [queryParams])

  return (
    <Layout>
      <div className="overflow-y-auto min-h-full p-4 pt-2 overflow-x-hidden flex flex-col">
        <div
          onClick={() => navigate('/')}
          className="transition-all  cursor-pointer  active:scale-95 duration-300 relative mb-1"
        >
          <div className=" w-9">
            <span className="block h-2 w-7 bg-[#69E07F] rounded-r4 ml-1 mt-px"></span>
            <span className="block h-2 w-5 bg-[#69E07F] rounded-r4 ml-0.5 mt-0.5"></span>
            <span className="block h-2 w-2 bg-[#69E07F] rounded-r4  mt-0.5"></span>
          </div>
        </div>
        <div className=" relative before:absolute before:content-[''] before:w-image before:h-px before:bg-borderInput before:-left-4 before:top-1/2 before:-translate-y-1/2">
          <img
            className="max-w-24 w-full mx-auto px-3 bg-white relative z-10"
            width="64"
            height="22"
            src={logo}
            alt="logo"
          />
        </div>
        <div className="mt-7">
          <h1 className="text-md font-bold">{t('Your Email Successfully Verified!')}</h1>
          <p className="mt-3 text-xs leading-relaxed">
            {t(
              'We are thrilled to inform you that your email has been successfully verified. This is a crucial step towards ensuring seamless communication and account security with us.',
            )}
          </p>
          <p className="mt-8 text-xs leading-relaxed">
            {t(
              'With your email now confirmed, you can expect to receive important updates, notifications, and exclusive offers straight to your inbox. Additionally, you`ll have full access to all the features and benefits of our services.',
            )}
          </p>
          <p className="mt-8 text-xs leading-relaxed">
            {t(
              'Should you have any questions or require further assistance, please don`t hesitate to reach out to  our support team. We`re here to help you every step of the way. Thank you for verifying your email with us. We look forward to serving you and providing you with an exceptional experience.',
            )}
          </p>
          <p className="mt-8 text-xs leading-relaxed">{t('Best regards,')}</p>
          <p className="mt-3 text-xs leading-relaxed">{t('White Apps LTD')}</p>
        </div>
        <div className="basic-auto flex-grow flex-shrink flex items-end justify-center w-full mt-12">
          <p className="text-xs4 text-center leading-3">
            White Apps LTD <br /> 61 Bridge Street, Herefordshire, Kington, United Kingdom, HR5 3DJ
          </p>
        </div>
      </div>
    </Layout>
  )
}
