import { getActivePlanPrice } from '@/helpers/getPlanPrice'
import * as amplitude from '@amplitude/analytics-browser'
import { Answers } from '@/types/types'
import { getValue } from '@/helpers/getObjectValue'
import { sha256 } from 'js-sha256'
import { getCookie } from '@/helpers/cookies'

export const purchaseFacebookApi = async () => {
  try {
    const storedData = localStorage.getItem('quizResult')
    const answers: Answers = storedData ? JSON.parse(storedData) : false
    const email = answers ? (getValue('email', answers) as string) : ''
    const prices = getActivePlanPrice()
    const AmplitudeID = amplitude.getSessionId()
    const time = Math.floor(new Date().getTime() / 1000.0)
    const countryCode = localStorage.getItem('countryCode')
    const body = {
      data: [
        {
          event_name: 'Purchase',
          event_time: time,
          action_source: 'website',
          event_id: AmplitudeID,
          user_data: {
            em: [sha256(email ?? '')],
            country: [sha256(countryCode ?? '')],
            fbc: getCookie('_fbc'),
            fbp: getCookie('_fbp'),
            client_ip_address: localStorage.getItem('ip'),
            client_user_agent: window.navigator.userAgent,
          },
          custom_data: {
            currency: 'USD',
            value: prices.price,
            predicted_ltv: prices.predictedPrice,
          },
        },
      ],
    }
    const response = await fetch(
      `https://graph.facebook.com/v20.0/${process.env.REACT_APP_FB_PIXEL_ID ?? ''}/events?access_token=${process.env.REACT_APP_FB_PIXEL_TOKEN_ID ?? ''}`,
      {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(body),
      },
    )
    const data = await response.json()
    return data
  } catch (e) {
    return { status: 412, body: { error: e } }
  }
}
