import { API_URL } from '@/constants/variables'
import Axios from 'axios'

const axiosInstance = Axios.create({
  baseURL: API_URL,
  paramsSerializer: {
    indexes: true, // use brackets with indexes
  },
})

export const axios = axiosInstance
