import { Answers } from '@/types/types'
import { getValue } from './getObjectValue'

export function returnDataObject(answers: Answers, config: Record<string, any>) {
  function subtractYears(date: Date, years: number) {
    date.setFullYear(date.getFullYear() - years)
    return date
  }
  const today: Date = new Date()
  const humanHeight = getValue('human-height', answers)
  const currentWeight = getValue('current-weight', answers)
  const goalWeight = getValue('goal-weight', answers)
  const email = getValue('email', answers)
  const age = getValue('your-age', answers) ?? 0
  const birthAt = subtractYears(today, +age)
  const name = getValue('your-name', answers)
  const activity = getValue('lifestyle', answers)
  let dailySteps = '4000'
  if (activity === 'medium') {
    dailySteps = '7000'
  } else if (activity === 'high') {
    dailySteps = '11000'
  }
  const diet = getValue('type-of-diet', answers) === 'vegan' ? 'vegetarian' : 'common'
  let metricType = 'US'
  let humanHeightFt = humanHeight?.[0]
  let humanHeightM = humanHeight?.[0]
  let currentWeightKg = currentWeight?.[0]
  let currentWeightPnd = currentWeight?.[0]
  let goalWeightKg = goalWeight?.[0]
  let goalWeightPnd = goalWeight?.[0]
  if (humanHeight?.[1] === 'FT') {
    humanHeightM = (+humanHeight?.[0] * 0.3).toFixed(2) + ''
  } else if (humanHeight?.[1] === 'CM') {
    humanHeightFt = (+humanHeight?.[0] * 0.032).toFixed(1) + ''
    humanHeightM = (+humanHeight?.[0] / 100).toFixed(2) + ''
    metricType = 'EU'
  }
  if (currentWeight?.[1] === 'kg') {
    currentWeightPnd = +currentWeight?.[0] * 2.2 + ''
  } else if (currentWeight?.[1] === 'lbs') {
    currentWeightKg = +currentWeight?.[0] * 0.4535 + ''
  }
  if (goalWeight?.[1] === 'kg') {
    goalWeightPnd = +goalWeight?.[0] * 2.2 + ''
  } else if (goalWeight?.[1] === 'lbs') {
    goalWeightKg = +goalWeight?.[0] * 0.4535 + ''
  }
  return {
    email,
    interfaceLanguage: 'en',
    metricType,
    profile: {
      condition: {
        height: {
          meters: humanHeightM,
          feets: humanHeightFt,
        },
        weight: {
          start: {
            pounds: currentWeightPnd,
            kilograms: currentWeightKg,
          },
          current: {
            pounds: currentWeightPnd,
            kilograms: currentWeightKg,
          },
          wanted: {
            pounds: goalWeightPnd,
            kilograms: goalWeightKg,
          },
        },
      },
      goals: {
        dailySteps: {
          count: dailySteps,
        },
      },
      birthAt,
      name,
      gender: 'female',
      personalWorkoutType: config.workoutType,
      dietType: diet,
      dailyActivity: activity,
    },
  }
}
