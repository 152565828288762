import water from '@/assets/images/water.svg'
import calories from '@/assets/images/calories.svg'
import trophy from '@/assets/images/trophy.svg'
import carbs from '@/assets/images/carbs.svg'
import move from '@/assets/images/move.svg'
import React from 'react'
import { useTranslation } from 'react-i18next'

interface GetMovingProps {
  lose: string
  percent: number
  betterme?: boolean
}
export function GetMoving(props: GetMovingProps) {
  const { t } = useTranslation()
  return (
    <div
      className={`${props.betterme ? 'rounded-r16' : 'rounded-r12'} mt-8  bg-goal border border-goalStroke p-6 bg-goal`}
    >
      {props.percent >= 20 && props.lose === 'lose' && !props.betterme && (
        <>
          <div className="flex items-start">
            <div className="mr-4 bg-green min-w-6 h-6 rounded-full flex items-center justify-center">
              <img
                className="max-w-5 min-w-5"
                src={trophy}
                width="24"
                height="24"
                alt="Height icon"
              />
            </div>
            <p className="text-xl2">
              {t('It`s worth')}: {t('lose')}{' '}
              <strong className="font-extrabold">{Math.round(props.percent)}% </strong>{' '}
              {t('of your weight')}
            </p>
          </div>
          <p className="mt-5 font-base pl-10">
            {t(
              'Mayo Clinic study: Losing over 20% of body weight improves metabolic health more than losing 5-10%.',
            )}
          </p>
        </>
      )}
      {props.percent >= 10 && props.percent < 20 && props.lose === 'lose' && !props.betterme && (
        <>
          <div className="flex items-start">
            <div className="mr-4 bg-green min-w-6 h-6 rounded-full flex items-center justify-center">
              <img
                className="max-w-5 min-w-5"
                src={carbs}
                width="24"
                height="24"
                alt="Height icon"
              />
            </div>
            <p className="text-xl2">
              {t('Gain health benefits')}: {t('lose')}{' '}
              <strong className="font-extrabold">{Math.round(props.percent)}% </strong>{' '}
              {t('of your weight')}
            </p>
          </div>
          <p className="mt-5 font-base pl-10">
            {t('Losing 10%+ body weight cuts risks of obesity-related problems, studies say.')}
          </p>
        </>
      )}
      {props.percent >= 5 && props.percent < 10 && props.lose === 'lose' && !props.betterme && (
        <>
          <div className="flex items-start">
            <div className="mr-4 bg-green min-w-6 h-6 rounded-full flex items-center justify-center">
              <img
                className="max-w-5 min-w-5"
                src={calories}
                width="24"
                height="24"
                alt="Height icon"
              />
            </div>
            <p className="text-xl2">
              {t('Realistic goal')}: {t('lose')}{' '}
              <strong className="font-extrabold">{Math.round(props.percent)}% </strong>{' '}
              {t('of your weight')}
            </p>
          </div>
          <p className="mt-5 font-base pl-10">
            {t(
              'CDC advises that shedding 4 kg monthly is safe, healthy, and can lower risks of chronic ailments like type 2 diabetes and heart disease.',
            )}
          </p>
        </>
      )}
      {props.percent < 5 && props.lose === 'lose' && !props.betterme && (
        <>
          <div className="flex items-start">
            <div className="mr-4 bg-green min-w-6 h-6 rounded-full flex items-center justify-center">
              <img
                className="max-w-5 min-w-5"
                src={water}
                width="24"
                height="24"
                alt="Height icon"
              />
            </div>
            <p className="text-xl2">
              {t('Get moving')}: {t('lose')}{' '}
              <strong className="font-extrabold">{Math.round(props.percent)}% </strong>{' '}
              {t('of your weight')}
            </p>
          </div>
          <p className="mt-5 font-base pl-10">
            {t(
              'A University of Utah study found that just 5 minutes of daily exercise can maintain fitness, boost energy, and improve sleep.',
            )}
          </p>
        </>
      )}
      {props.percent >= 0 && props.lose === 'gain' && !props.betterme && (
        <>
          <div className="flex items-start">
            <div className="mr-4 bg-green min-w-6 h-6 rounded-full flex items-center justify-center">
              <img
                className="max-w-5 min-w-5"
                src={water}
                width="24"
                height="24"
                alt="Height icon"
              />
            </div>
            <p className="text-xl2">
              {t('Get moving')}: {t('gain')}{' '}
              <strong className="font-extrabold">{Math.round(props.percent)}% </strong>{' '}
              {t('of your weight')}
            </p>
          </div>
          <p className="mt-5 font-base pl-10">
            {t(
              'A University of Utah study found that just 5 minutes of daily exercise can maintain fitness, boost energy, and improve sleep.',
            )}
          </p>
        </>
      )}
      {props.percent >= 0 && props.percent < 5 && props.lose === 'lose' && props.betterme && (
        <>
          <div className="flex items-center">
            <div className="mr-2 min-w-6 ">
              <img
                className="max-w-6 min-w-6"
                src={move}
                width="24"
                height="24"
                alt="Height icon"
              />
            </div>
            <p className="text-xl2">
              {t('Get moving')}: {t('lose')}{' '}
              <strong className="font-extrabold">{Math.round(props.percent)}% </strong>{' '}
              {t('of your weight')}
            </p>
          </div>
          <p className="mt-5 font-base pl-10">
            A study by the University of Utah found that working out just 5 minutes per day can
            maintain your level of fitness, improve energy levels, and lead to better sleep.
          </p>
        </>
      )}
      {props.percent >= 5 && props.percent < 10 && props.lose === 'lose' && props.betterme && (
        <>
          <div className="flex items-center">
            <div className="mr-2 min-w-6 ">
              <img
                className="max-w-6 min-w-6"
                src={move}
                width="24"
                height="24"
                alt="Height icon"
              />
            </div>
            <p className="text-xl2">
              Realistic goal: {t('lose')}{' '}
              <strong className="font-extrabold">{Math.round(props.percent)}% </strong>{' '}
              {t('of your weight')}
            </p>
          </div>
          <p className="mt-5 font-base pl-10">
            According to the CDC, burning off 4 kg per month is safe, healthy, and can reduce your
            risk of chronic health problems like type 2 diabetes and heart disease.
          </p>
        </>
      )}
      {props.percent >= 10 && props.percent < 20 && props.lose === 'lose' && props.betterme && (
        <>
          <div className="flex items-center">
            <div className="mr-2 min-w-6 ">
              <img
                className="max-w-6 min-w-6"
                src={move}
                width="24"
                height="24"
                alt="Height icon"
              />
            </div>
            <p className="text-xl2">
              Unlock health benefits {t('lose')}{' '}
              <strong className="font-extrabold">{Math.round(props.percent)}% </strong>{' '}
              {t('of your weight')}
            </p>
          </div>
          <p className="mt-5 font-base pl-10">
            Studies have shown that losing 10% or more of your body weight can reduce your risk of
            some obesity-related conditions, such as heart attacks, high blood sugar, and
            inflammation in your blood vessels.
          </p>
        </>
      )}
      {props.percent >= 20 && props.lose === 'lose' && props.betterme && (
        <>
          <div className="flex items-center">
            <div className="mr-2 min-w-6 ">
              <img
                className="max-w-6 min-w-6"
                src={move}
                width="24"
                height="24"
                alt="Height icon"
              />
            </div>
            <p className="text-xl2">
              It’s worth it {t('lose')}{' '}
              <strong className="font-extrabold">{Math.round(props.percent)}% </strong>{' '}
              {t('of your weight')}
            </p>
          </div>
          <p className="mt-5 font-base pl-10">
            The Mayo Clinic conducted a study which found that overweight people who lose 20% or
            more of their body weight are more than twice as likely to show improved metabolic
            health as those who only lose 5-10%.
          </p>
        </>
      )}
      {props.percent >= 0 && props.lose === 'gain' && props.betterme && (
        <>
          <div className="flex items-center">
            <div className="mr-2 min-w-6 ">
              <img
                className="max-w-6 min-w-6"
                src={move}
                width="24"
                height="24"
                alt="Height icon"
              />
            </div>
            <p className="text-xl2">
              {t('Get moving')}: {t('gain')}{' '}
              <strong className="font-extrabold">{Math.round(props.percent)}% </strong>{' '}
              {t('of your weight')}
            </p>
          </div>
          <p className="mt-5 font-base pl-10">
            A study by the University of Utah found that working out just 5 minutes per day can
            maintain your level of fitness, improve energy levels, and lead to better sleep.
          </p>
        </>
      )}
    </div>
  )
}
