import { Answers } from '@/types/types'
import React from 'react'
import { getValue } from '@/helpers/getObjectValue'

interface TitleWithWeightProps {
  answers: Answers
}

export const TitleWithWeight = (props: TitleWithWeightProps) => {
  const goalWeight = getValue('goal-weight', props.answers)
  const newDate = new Date()
  const newDateFit = new Date()
  newDateFit.setDate(newDate.getDate() + 41)
  return (
    <>
      <h2 className="text-center font-girloy mt-5 mb-6 text-dark text-xl35 font-semibold -mx-1 tracking-tight  col-span-2 relative z-10">
        How confident are you in reaching{' '}
        {goalWeight ? goalWeight?.[0] + goalWeight?.[1].toLowerCase() : ''} by{' '}
        {newDateFit.toLocaleString('en-us', { day: 'numeric', month: 'short' })}
      </h2>
    </>
  )
}
