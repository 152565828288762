import { Answer, Answers } from '@/types/types'
import React, { useEffect, useState } from 'react'
import Image from '../Image/Image'

interface LoaderProps {
  answers: Answers
  active?: boolean
  mode?: string
  triggerNextStep: () => void
  onAnswer: (answer: Answer, step?: number) => void
}

const INITIAL_OFFSET = 25
const circleConfig = {
  viewBox: '0 0 38 38',
  x: '19',
  y: '19',
  radio: '15.91549430918954',
}

const pace = 100

export const Loader = (props: LoaderProps) => {
  const [progressBar, setProgressBar] = useState(0)

  useEffect(() => {
    if (!props.active) return

    const timeoutId = setTimeout(() => {
      if (progressBar >= 100) {
        props.triggerNextStep()
        return
      }
      setProgressBar(progressBar + 1)
    }, pace)

    return () => {
      clearTimeout(timeoutId)
    }
  }, [progressBar, props.active])

  useEffect(() => {
    if (props.active) return
    setProgressBar(0)

    return () => {
      setProgressBar(0)
    }
  }, [props.active])

  return (
    <>
      <div className="py-2 px-4 -mt-4 flex items-center border bg-[#FAFAFA] rounded-2xl border-[#0000002b] relative z-40 ">
        <figure className="relative flex justify-center">
          <svg viewBox={circleConfig.viewBox} className="w-[60px]">
            <circle
              className="donut-segment"
              cx={circleConfig.x}
              cy={circleConfig.y}
              r={circleConfig.radio}
              fill="transparent"
              stroke="#919CA4"
              strokeWidth="5"
              strokeLinecap="round"
            />
            <circle
              className="donut-segment"
              cx={circleConfig.x}
              cy={circleConfig.y}
              r={circleConfig.radio}
              fill="transparent"
              stroke="#13D16A"
              strokeWidth="5"
              strokeLinecap="round"
              strokeDasharray={`${progressBar} ${100 - progressBar}`}
              strokeDashoffset={INITIAL_OFFSET}
            />
          </svg>
          <p className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 text-xs font-semibold font-girloy">
            {progressBar}
            <span className="text-xs font-normal">%</span>
          </p>
        </figure>
        <p className="ml-3 font-extrabold text-md">Creating your personalized plan...</p>
      </div>
      <div className="w-full overflow-hidden">
        <div className="relative flex w-[calc(200%)] transition-transform translate-after-delayLoader">
          <div className="mt-8 w-[calc(100%)] overflow-hidden">
            <h2 className="p-0 mb-0 text-center text-xl4 text-dark">
              Fit4Me was made for people just <span className="text-green">like you</span>
            </h2>
            <Image className="mb-3" src="/img/base-screen-4-0-1.jpg" alt="slide-1" />
            <p className="text-xl4 mb-1 font-bold text-center text-dark">
              <span className="text-green">83%</span> {"of Fit4Me's users"}
            </p>
            <p className="text-xl2 text-center text-dark font-semibold">
              report that they are satisfied with the personal plan
            </p>
          </div>

          <div className="mt-8 w-[calc(100%)] overflow-hidden">
            <h2 className="p-0 mb-4 text-center text-xl4 text-dark">
              Almost <span className="text-green">done!</span>
            </h2>
            <Image className="mb-3" src="/img/base-screen-4-0-2.jpg" alt="slide-2" />
            <p className="text-xl4 mb-1 font-bold text-center text-dark">
              <span className="text-green">75%</span> {"of Fit4Me's users"}
            </p>
            <p className="text-xl2 text-center text-dark font-semibold">
              find their workouts accurate, useful and easy to follow
            </p>
          </div>
        </div>
      </div>
    </>
  )
}
