import { Answers } from '@/types/types'

export const getValue = (fieldName: string, object: Answers) => {
  for (const entry of Object.values(object)) {
    for (const items of entry) {
      const value = items[fieldName]
      if (value !== undefined) {
        return value.length > 1 ? value : value[0]
      }
    }
  }
}
