import { Answer, Answers, RangeBodyType } from '@/types/types'
import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { getValue } from '@/helpers/getObjectValue'
import Lottie from 'lottie-react'
import animationData from '@/assets/images/swipe.json'
import Image from '@/components/Image/Image'

interface RangeBodyProps {
  attributes: RangeBodyType
  onAnswer: (answer: Answer, step?: number) => void
  handleRemoveAnswer: (answer: Answer) => void
  name: string
  step: number
  isActive: boolean
  answers: Answers
}

export function RangeBody(props: RangeBodyProps) {
  const lottieRef: any = useRef()
  const { t } = useTranslation()
  const [value, setValue] = useState(props.attributes.currentValue)
  const [showLottie, setShowLottie] = useState(true)
  useEffect(() => {
    if (props.isActive) {
      const choosedValue = getValue(props.name, props.answers) ?? null
      lottieRef.current.play()
      setTimeout(() => {
        lottieRef.current.pause()
        setShowLottie(false)
      }, 5000)
      if (!choosedValue && props.name === 'range-current-body') {
        props.onAnswer({ [props.name]: [value + ''] })
      }
      if (
        choosedValue &&
        choosedValue !== 'NaN' &&
        choosedValue !== null &&
        choosedValue !== 'null'
      ) {
        setValue(+choosedValue)
        props.onAnswer({ [props.name]: [+choosedValue + ''] })
      }
    }
  }, [props.isActive])
  useEffect(() => {
    if (props.isActive && String(value) !== 'NaN' && value !== null && String(value) !== 'null') {
      props.onAnswer({ [props.name]: [value + ''] })
    }
  }, [value])
  const changeRange = (e: any) => {
    setValue(+e.target.value)
  }
  const touchMoveFun = (e: any) => {
    const swipeVal = +((e.changedTouches[0].pageX * 8) / window.innerWidth - 0.5).toFixed(0)
    setValue(Math.abs(swipeVal))
  }

  return (
    <div onTouchMove={touchMoveFun} onTouchStart={touchMoveFun}>
      <div className="relative mb-14 mt-12 h-[290px] bg-[#EFF5EF] rounded-[36px] overflow-hidden">
        <Image
          className={`${value === 0 ? 'opacity-100' : 'opacity-0'} h-full mx-auto images relative transition-all duration-300`}
          src="/img/range/range-0.png"
          webp="/img/range/range-0.webp"
          alt="body image"
        />
        <Image
          className={`${value === 1 ? 'opacity-100' : 'opacity-0'} h-full mx-auto transition-all duration-300 images absolute top-0 left-1/2 -translate-x-1/2`}
          src="/img/range/range-1.png"
          webp="/img/range/range-1.webp"
          alt="body image"
        />

        <Image
          className={`${value === 2 ? 'opacity-100' : 'opacity-0'} h-full mx-auto transition-all duration-300 images absolute top-0 left-1/2 -translate-x-1/2`}
          src="/img/range/range-2.png"
          webp="/img/range/range-2.webp"
          alt="body image"
        />

        <Image
          className={`${value === 3 ? 'opacity-100' : 'opacity-0'} h-full mx-auto transition-all duration-300 images absolute top-0 left-1/2 -translate-x-1/2`}
          src="/img/range/range-3.png"
          webp="/img/range/range-3.webp"
          alt="body image"
        />

        <Image
          className={`${value === 4 ? 'opacity-100' : 'opacity-0'} h-full mx-auto transition-all duration-300 images absolute top-0 left-1/2 -translate-x-1/2`}
          src="/img/range/range-4.png"
          webp="/img/range/range-4.webp"
          alt="body image"
        />

        <Image
          className={`${value === 5 ? 'opacity-100' : 'opacity-0'} h-full mx-autotransition-all duration-300 images absolute top-0 left-1/2 -translate-x-1/2`}
          src="/img/range/range-5.png"
          webp="/img/range/range-5.webp"
          alt="body image"
        />
        <Image
          className={`${value === 6 ? 'opacity-100' : 'opacity-0'} h-full mx-autotransition-all duration-300 images absolute top-0 left-1/2 -translate-x-1/2`}
          src="/img/range/range-6.png"
          webp="/img/range/range-6.webp"
          alt="body image"
        />
        <Image
          className={`${value === 7 ? 'opacity-100' : 'opacity-0'} h-full mx-autotransition-all duration-300 images absolute top-0 left-1/2 -translate-x-1/2`}
          src="/img/range/range-7.png"
          webp="/img/range/range-7.webp"
          alt="body image"
        />
      </div>
      <div className="relative h-4 bg-progress rounded-lg px-8">
        <input
          className="input-range relative z-20"
          type="range"
          value={value === null ? 0 : value}
          step={1}
          max={7}
          onChange={(e) => changeRange(e)}
        />
        <div className="range-dots w-full">
          <span className="block w-3 h-3 rounded-full bg-dots border-2 border-white absolute top-1/2 -translate-y-1/2 z-10"></span>
          <span className="block w-3 h-3 rounded-full bg-dots border-2 border-white absolute top-1/2 -translate-y-1/2 z-10"></span>
          <span className="block w-3 h-3 rounded-full bg-dots border-2 border-white absolute top-1/2 -translate-y-1/2 z-10"></span>
          <span className="block w-3 h-3 rounded-full bg-dots border-2 border-white absolute top-1/2 -translate-y-1/2 z-10"></span>
          <span className="block w-3 h-3 rounded-full bg-dots border-2 border-white absolute top-1/2 -translate-y-1/2 z-10"></span>
          <span className="block w-3 h-3 rounded-full bg-dots border-2 border-white absolute top-1/2 -translate-y-1/2 z-10"></span>
          <span className="block w-3 h-3 rounded-full bg-dots border-2 border-white absolute top-1/2 -translate-y-1/2 z-10"></span>
          <span className="block w-3 h-3 rounded-full bg-dots border-2 border-white absolute top-1/2 -translate-y-1/2 z-10"></span>
        </div>
      </div>
      <div className="flex items-center justify-between mt-4">
        <p className="text-md font-medium">
          {t('Body fat')} <span>{'>'}</span> 15%
        </p>
        <p>
          {'>'}
          40%
        </p>
      </div>
      <div
        className={
          (showLottie ? 'opacity-100' : 'opacity-0') +
          ' absolute -bottom-10 w-20 left-1/2 -translate-x-1/2 transition-all'
        }
      >
        <Lottie lottieRef={lottieRef} animationData={animationData} loop={true} autoplay={false} />
      </div>
    </div>
  )
}
