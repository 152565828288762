import flag from '@/assets/images/flag.svg'
import kg from '@/assets/images/kg.svg'
import React from 'react'
import { PaywallContent } from '@/types/types'
import { useTranslation } from 'react-i18next'

interface PlansHeaderProps {
  paywallContent: PaywallContent | undefined
  isFreeTrial: boolean
  goal: string[] | string | undefined
  goalWeight: string[] | string | undefined
}

export const PlansHeader = (props: PlansHeaderProps) => {
  const { t } = useTranslation()
  const { paywallContent, isFreeTrial, goal, goalWeight } = props
  return (
    <>
      {paywallContent?.choosePlanBlock && (
        <>
          <h3
            className="[&>mark]:bg-transparent [&>mark]:text-green2 text-xl12  font-extrabold mt-4 -mx-4 text-center mb-3"
            dangerouslySetInnerHTML={{
              __html: isFreeTrial
                ? paywallContent.choosePlanBlock.titleTrial
                : paywallContent.choosePlanBlock.title,
            }}
          ></h3>
          {isFreeTrial && paywallContent.choosePlanBlock.description && (
            <p className="text-dark font-medium opacity-50 text-center my-3">
              {paywallContent.choosePlanBlock.description}
            </p>
          )}
          <div className="grid grid-cols-2 gap-4 mb-2">
            <div className="bg-checked rounded-r12 flex items-center py-2 px-3">
              <img className="w-6 mr-3 mb-1" src={flag} alt="flag" />
              <div>
                <p className="opacity-50 text-xs2 font-semibold">{t('Goal')}</p>
                <p className="text-md font-bold">{goal}</p>
              </div>
            </div>
            <div className="bg-checked rounded-r12 flex items-center py-2 px-3">
              <img className="w-6 mr-3 mb-1" src={kg} alt="kg" />
              <div>
                <p className="opacity-50 text-xs2 font-semibold">{t('Target weight')}</p>
                <p className="text-md font-bold">
                  {goalWeight?.[0]} {goalWeight?.[1]}
                </p>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  )
}
