import { ResponseError } from '@/app/api/error-entity'
import { axios } from '@/app/settings/axios/axios'
import { AxiosResponse, RawAxiosRequestConfig } from 'axios'

export interface CustomAxiosRequestConfig<P = any> extends RawAxiosRequestConfig<P> {
  isRetry?: boolean
}

export class BaseHttpServices {
  getErrorMessage(message: string): string | undefined {
    return message
  }

  onError(error: any) {
    if (!error.response) return error

    const responseError = new ResponseError(
      error.response,
      this.getErrorMessage(error.response?.data?.message),
    )

    return responseError
  }

  async get<T = any, R = AxiosResponse<T>, D = any>(
    url: string,
    config?: CustomAxiosRequestConfig<D>,
  ): Promise<R> {
    try {
      const response = await axios.get(url, config)

      return response as unknown as Promise<R>
    } catch (error) {
      throw this.onError(error)
    }
  }

  async post<T = any, R = AxiosResponse<T>>(
    url: string,
    data?: any,
    config?: CustomAxiosRequestConfig<any>,
  ): Promise<R> {
    try {
      const response = await axios.post(url, data, config)

      return response as unknown as Promise<R>
    } catch (error) {
      throw this.onError(error)
    }
  }

  async put<T = any, R = AxiosResponse<T>>(
    url: string,
    data?: any,
    config?: CustomAxiosRequestConfig<any>,
  ): Promise<R> {
    try {
      const response = await axios.put(url, data, config)

      return response as unknown as Promise<R>
    } catch (error) {
      throw this.onError(error)
    }
  }

  async delete<T = any, R = AxiosResponse<T>>(
    url: string,
    config?: CustomAxiosRequestConfig<any>,
  ): Promise<R> {
    try {
      const response = await axios.delete(url, config)

      return response as unknown as Promise<R>
    } catch (error) {
      throw this.onError(error)
    }
  }
}
