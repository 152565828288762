class _LocalStorageService {
  get(itemName: string) {
    const item = localStorage.getItem(itemName)
    const numPatt = /^\d+$/
    const jsonPatt = /[[{].*[}\]]/

    try {
      if (!item) return null

      if (jsonPatt.test(item)) return JSON.parse(item)
      if (numPatt.test(item)) return parseFloat(item)

      return item
    } catch {
      return null
    }
  }

  set(itemName: string, item: any) {
    if (typeof item === 'object') {
      localStorage.setItem(itemName, JSON.stringify(item))
    } else {
      localStorage.setItem(itemName, item)
    }
  }

  remove(itemName: string) {
    localStorage.removeItem(itemName)
  }
}

export const LocalStorageService = new _LocalStorageService()
