// export enum PAYMENT_API_ERRORS {
//   '' = '',
// }

export function getPaymentPlansApiErrorMessage(message: string): string {
  // const [messageType] = message.split(':')
  // return PAYMENT_API_ERRORS[message] || message || ''
  console.log(message)
  return message
}
