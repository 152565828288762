import React from 'react'
import { Header } from '../../Header/Header'
import Layout from '../../Layout'
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from '@radix-ui/react-accordion'
import arrow from '@/assets/images/accordion-arrow.svg'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

export function FAQ() {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const BackToHomePage = () => {
    navigate(-1)
  }
  return (
    <>
      <Layout>
        <div className="overflow-y-auto min-h-full p-4 pt-0 overflow-x-hidden">
          <Header
            title="FAQs"
            onBackClick={BackToHomePage}
            showBtn={true}
            isPage={true}
            isLastQuestion={false}
            isLastStep={false}
            fixed={true}
          />
          <Accordion type="single" collapsible>
            <AccordionItem className="border-b border-border" value="item-1">
              <AccordionTrigger className="flex flex-1 items-center justify-between py-4 text-sm font-medium transition-all  [&[data-state=open]>img]:rotate-180 text-base font-semibold w-full flex items-center justify-between text-left">
                {t('How do I sign up')}?
                <img
                  className="h-4 w-4 shrink-0 text-muted-foreground transition-transform duration-200 ml-4"
                  src={arrow}
                  alt="arrow"
                />
              </AccordionTrigger>
              <AccordionContent className="overflow-hidden text-sm data-[state=closed]:animate-accordion-up data-[state=open]:animate-accordion-down">
                <p className="pb-7 text-left text-light text-md">
                  {t(
                    'To get started, simply go to Fit4me web page, and answer a few quick questions about your workout and nutrition preferences, goals, and overall fitness level. We will also ask for your email: please make sure you&lsquo;ve entered it correctly. Once we have  plan designed just for you, and provide you with the app download link so that you can get started right away.',
                  )}
                </p>
              </AccordionContent>
            </AccordionItem>
            <AccordionItem className="border-b border-border mt-7" value="item-2">
              <AccordionTrigger className="flex flex-1 items-center justify-between py-4 text-sm font-medium transition-all  [&[data-state=open]>img]:rotate-180 text-base font-semibold w-full flex items-center justify-between text-left">
                {t('How will I receive my customized meal and workout plan')}?
                <img
                  className="h-4 w-4 shrink-0 text-muted-foreground transition-transform duration-200 ml-4"
                  src={arrow}
                  alt="arrow"
                />
              </AccordionTrigger>
              <AccordionContent className="overflow-hidden text-sm data-[state=closed]:animate-accordion-up data-[state=open]:animate-accordion-down">
                <p className="pb-7 text-left text-light text-md">
                  {t(
                    'You`ll receive a unique download link that will take you to the AppStore to download the app. Once the app is installed on your phone, you need to sign into the app using your email. You will have unlimited access to all the features within the app for the duration of your paid period. If you experience any difficulties with downloading or accessing the app, please contact us at',
                  )}{' '}
                  <a
                    className="transition-all hover:text-green"
                    href="mailto: support@fit4me-life.com"
                  >
                    support@fit4me-life.com
                  </a>
                  .
                </p>
              </AccordionContent>
            </AccordionItem>
            <AccordionItem className="border-b border-border mt-7" value="item-3">
              <AccordionTrigger className="flex flex-1 items-center justify-between py-4 text-sm font-medium transition-all  [&[data-state=open]>img]:rotate-180 text-base font-semibold w-full flex items-center justify-between text-left">
                {t('When will I receive my customized meal and workout plan')}?
                <img
                  className="h-4 w-4 shrink-0 text-muted-foreground transition-transform duration-200 ml-4"
                  src={arrow}
                  alt="arrow"
                />
              </AccordionTrigger>
              <AccordionContent className="overflow-hidden text-sm data-[state=closed]:animate-accordion-up data-[state=open]:animate-accordion-down">
                <p className="pb-7 text-left text-light text-md">
                  {t(
                    'Our smart algorithms are highly precise and are able to customize your fitness plan according to your specific needs. The unique download link will be generated  right after your payment. The plan will become available as soon as you download the app using your unique link.',
                  )}
                </p>
              </AccordionContent>
            </AccordionItem>
            <AccordionItem className="border-b border-border mt-7" value="item-4">
              <AccordionTrigger className="flex flex-1 items-center justify-between py-4 text-sm font-medium transition-all  [&[data-state=open]>img]:rotate-180 text-base font-semibold w-full flex items-center justify-between text-left">
                {t('Do we always offer free trials')}?
                <img
                  className="h-4 w-4 shrink-0 text-muted-foreground transition-transform duration-200 ml-4"
                  src={arrow}
                  alt="arrow"
                />
              </AccordionTrigger>
              <AccordionContent className="overflow-hidden text-sm data-[state=closed]:animate-accordion-up data-[state=open]:animate-accordion-down">
                <p className="pb-7 text-left text-light text-md">
                  {t(
                    'Please note that if a free trial is offered, this will be explicitly stated on the price screen before the checkout. If this is not the case, you will purchase our subscription without a free trial.',
                  )}
                </p>
              </AccordionContent>
            </AccordionItem>
            <AccordionItem className="border-b border-border mt-7" value="item-5">
              <AccordionTrigger className="flex flex-1 items-center justify-between py-4 text-sm font-medium transition-all  [&[data-state=open]>img]:rotate-180 text-base font-semibold w-full flex items-center justify-between text-left">
                {t('How will you bill me')}?
                <img
                  className="h-4 w-4 shrink-0 text-muted-foreground transition-transform duration-200 ml-4"
                  src={arrow}
                  alt="arrow"
                />
              </AccordionTrigger>
              <AccordionContent className="overflow-hidden text-sm data-[state=closed]:animate-accordion-up data-[state=open]:animate-accordion-down">
                <p className="pb-7 text-left text-light text-md">
                  {t(
                    'Generally we offer subscriptions that automatically renew. Depending on what option you choose, your subscription will be renewed at the end of each period.',
                  )}
                </p>
              </AccordionContent>
            </AccordionItem>
            <AccordionItem className="border-b border-border mt-7" value="item-6">
              <AccordionTrigger className="flex flex-1 items-center justify-between py-4 text-sm font-medium transition-all  [&[data-state=open]>img]:rotate-180 text-base font-semibold w-full flex items-center justify-between text-left">
                {t('Can I cancel my subscription')}?
                <img
                  className="h-4 w-4 shrink-0 text-muted-foreground transition-transform duration-200 ml-4"
                  src={arrow}
                  alt="arrow"
                />
              </AccordionTrigger>
              <AccordionContent className="overflow-hidden text-sm data-[state=closed]:animate-accordion-up data-[state=open]:animate-accordion-down">
                <p className="pb-7 text-left text-light text-md">
                  {t(
                    'Your subscription renews automatically at the end of each period until you cancel. Note that deleting the app does not cancel your subscriptions. You may cancel a trial or a subscription by Subscription on your iPhone or by email',
                  )}
                  :{' '}
                  <a
                    className="transition-all hover:text-green"
                    href="mailto: support@fit4me-life.com"
                  >
                    support@fit4me-life.com
                  </a>
                  .
                </p>
              </AccordionContent>
            </AccordionItem>
            <AccordionItem className="border-b border-border mt-7" value="item-7">
              <AccordionTrigger className="flex flex-1 items-center justify-between py-4 text-sm font-medium transition-all  [&[data-state=open]>img]:rotate-180 text-base font-semibold w-full flex items-center justify-between text-left">
                {t('I do not have access to my subscription any longer, what happened')}?
                <img
                  className="h-4 w-4 shrink-0 text-muted-foreground transition-transform duration-200 ml-4"
                  src={arrow}
                  alt="arrow"
                />
              </AccordionTrigger>
              <AccordionContent className="overflow-hidden text-sm data-[state=closed]:animate-accordion-up data-[state=open]:animate-accordion-down">
                <p className="pb-7 text-left text-light text-md">
                  {t(
                    'We sometimes offer non-renewing subscriptions that expire after the subscription period (1 month, 3 months or 6 months). Once your chosen subscription expires, you lose access to the app. If you wish to continue using the app after your initial subscription expired, you may repurchase it within the app or on Fit4me web page  at any time.',
                  )}
                </p>
              </AccordionContent>
            </AccordionItem>
          </Accordion>
        </div>
      </Layout>
    </>
  )
}
