import { getValue } from '@/helpers/getObjectValue'
import { Answers } from '@/types/types'
import React, { useEffect, useState } from 'react'

export function ProgressStep(props: { title: string; active?: boolean; answers: Answers }) {
  const progress = getValue('progress-step', props.answers)
  const [value, setValue] = useState(progress ? 100 : 0)
  let difference = progress ? 100 : 0
  const startProgress = () => {
    const intervalId = setInterval(() => {
      ++difference
      if (difference >= 100) {
        clearInterval(intervalId)
        setValue(difference)
      } else if (difference > 40 && difference < 75) {
        clearInterval(intervalId)
        setValue(difference)
        startProgressSlow()
      } else {
        setValue(difference)
      }
    }, 20)
    return () => clearInterval(intervalId)
  }
  const startProgressSlow = () => {
    const intervalId = setInterval(() => {
      ++difference
      if (difference > 75) {
        clearInterval(intervalId)
        setValue(difference)
        startProgress()
      } else {
        setValue(difference)
      }
    }, 50)
    return () => clearInterval(intervalId)
  }

  useEffect(() => {
    if (props.active) {
      startProgress()
    }
  }, [props.active])
  return (
    <div className="mb-4">
      <div className="flex items-center justify-between">
        <span className="font-semibold">{props.title}</span>
        <span className="font-extrabold">
          <span>{props.active || progress ? value : 0}</span>
          <span>%</span>
        </span>
      </div>
      <div className="h-1 bg-[#D9D9D9] w-full rounded-r8 mt-2">
        <span
          style={{ width: props.active || progress ? value + '%' : '0' }}
          className="h-1 rounded-r8 block bg-green"
        ></span>
      </div>
    </div>
  )
}
