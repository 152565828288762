import React, { useEffect, useRef } from 'react'
import { Answer, Answers, RadioButtonType } from '@/types/types'
import { getValue } from '@/helpers/getObjectValue'

interface RadioButtonProps {
  attributes: RadioButtonType
  onAnswer: (answer: Answer) => void
  triggerNextStep: () => void
  name: string
  answers: Answers
  smallWidth?: boolean
}

export default function RadioCard(props: RadioButtonProps) {
  const checkbox = useRef<HTMLInputElement>(null)
  const handleChange = () => {
    props.onAnswer({
      [props.name]: [props.attributes.text],
    })
    props.triggerNextStep()
  }
  useEffect(() => {
    const choosedValue = getValue(props.name, props.answers)
    if (choosedValue) {
      const inputs = document.querySelectorAll('input')
      inputs.forEach((input) => {
        if (input.value === choosedValue) {
          input.checked = true
        }
      })
    }
  }, [])
  return (
    <div>
      <label
        className={`
             bg-white items-center checkbox font-semibold border relative p-1 pt-0 border-[#EFF1F4] m-0 rounded-xl  min-h-[74px] cursor-pointer  transition duration-300 font-girloy  after:absolute after:content=[''] after:bottom-6 after:w-6 after:h-6  after:border after:border-[#17171775] after:bg-transparent after:right-4 after:rounded-full  active:scale-95
            ${
              checkbox.current?.checked
                ? ' !bg-gray   border-gray after:!bg-dark after:border-dark border-borderChecked after:bg-tick after:bg-center after:bg-60% after:bg-no-repeat '
                : ''
            } ${props.smallWidth ? ' w-2/3' : ''}`}
      >
        {props.attributes?.img && (
          <span className="flex items-center w-full justify-center">
            <img
              className="w-auto max-w-full object-contain h-[138px] w-auto"
              width={40}
              height={40}
              alt="Radio img"
              src={props.attributes?.img}
            />
          </span>
        )}
        <span className="h-16 flex items-center leading-normal block p-3 pr-10 bg-white rounded-r12 text-base text-semibold">
          {props.attributes.text}
          {props.attributes?.small && (
            <small className="block  text-dark text-xs2 font-semibold opacity-65">
              {props.attributes.small}
            </small>
          )}
        </span>
        <input
          type="radio"
          onClick={handleChange}
          name={props.name}
          value={props.attributes.text}
          className="hidden"
          ref={checkbox}
        />
      </label>
    </div>
  )
}
