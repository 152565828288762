import React, { useMemo } from 'react'
import { BMI } from './BMI'
import weight from '@/assets/images/profile/weight.svg'
import goal from '@/assets/images/profile/goal.svg'
import level from '@/assets/images/profile/level.svg'
import tempo from '@/assets/images/profile/tempo.svg'
import restriction from '@/assets/images/profile/restrictions.svg'
import { Answer, Answers } from '@/types/types'
import { getValue } from '@/helpers/getObjectValue'
import { useTranslation } from 'react-i18next'
import Image from '@/components/Image/Image'

interface ProfileProps {
  active: boolean
  answers: Answers
  onAnswer: (answer: Answer, step?: number) => void
}
export function Profile(props: ProfileProps) {
  const { t } = useTranslation()
  const currentWeight = getValue('current-weight', props.answers)
  const goalWeight = getValue('goal-weight', props.answers)
  const rangeCurrentBody = getValue('range-current-body', props.answers)?.[0] ?? 0
  const tempoVal = getValue('how-fast', props.answers)
  const fitnessLevel = getValue('fitness-level', props.answers)
  const arrayRestrictions = getValue('array-restrictions', props.answers)

  const currentBody = useMemo(() => {
    const current = Number(rangeCurrentBody)
    if (Number(rangeCurrentBody) <= 5) return current
    return 5
  }, [rangeCurrentBody])

  const restrictions = () => {
    if (typeof arrayRestrictions === 'string' && arrayRestrictions === 'No, thanks') {
      return <p className="inline  text-dark text-xs2 font-semibold opacity-65 pr-1">No</p>
    }

    if (typeof arrayRestrictions === 'string') {
      return (
        <p className="inline  text-dark text-xs2 font-semibold opacity-65 pr-1">
          {arrayRestrictions}
        </p>
      )
    }

    if (typeof arrayRestrictions === 'object') {
      const listItems = arrayRestrictions.map((item, i) => (
        <p key={i} className="inline  text-dark text-xs2 font-semibold opacity-65 pr-1">
          {item}{' '}
        </p>
      ))

      return listItems
    }
  }

  return (
    <div>
      <BMI answers={props.answers} active={props.active} />
      <div className="flex items-center">
        <div>
          <div className="py-2 px-4 rounded-r12 bg-checked border border-green mt-2 flex items-center">
            <img className="min-w-6 mr-3" src={weight} width="24" height="24" alt="icon" />
            <div>
              <h6 className="font-extrabold">{t('Current weight')}</h6>
              <p className="block  text-dark text-xs2 font-semibold opacity-65">
                {currentWeight?.[0]} {currentWeight?.[1]}
              </p>
            </div>
          </div>
          <div className="py-2 px-4 rounded-r12 bg-checked border border-green mt-2 flex items-center">
            <img className="min-w-6 mr-3" src={goal} width="24" height="24" alt="icon" />
            <div>
              <h6 className="font-extrabold"> {t('Goal')} </h6>
              <p className="block  text-dark text-xs2 font-semibold opacity-65">
                {goalWeight?.[0]} {goalWeight?.[1]}
              </p>
            </div>
          </div>
          <div className="py-2 px-4 rounded-r12 bg-checked border border-green mt-2 flex items-center">
            <img className="min-w-6 mr-3" src={level} width="24" height="24" alt="icon" />
            <div>
              <h6 className="font-extrabold">{t('Fitness Level')}</h6>
              <p className="block  text-dark text-xs2 font-semibold opacity-65">{fitnessLevel}</p>
            </div>
          </div>
          <div className="py-2 px-4 rounded-r12 bg-checked border border-green mt-2 flex items-center">
            <img className="min-w-6 mr-3" src={tempo} width="24" height="24" alt="icon" />
            <div>
              <h6 className="font-extrabold">{t('Tempo')}</h6>
              <p className="block  text-dark text-xs2 font-semibold opacity-65">{tempoVal}</p>
            </div>
          </div>
          <div className="py-2 px-4 rounded-r12 bg-checked border border-green mt-2 flex items-center">
            <img className="min-w-6 mr-3" src={restriction} width="24" height="24" alt="icon" />
            <div>
              <h6 className="font-extrabold">{t('Restrictions')}</h6>
              <div className="leading-none">{restrictions()}</div>
            </div>
          </div>
        </div>
        <div className="-mr-5 relative min-w-body max-w-body">
          <Image
            className="max-w-48 w-full mx-auto transition-all duration-300 profile-image"
            src={`/img/range/range${currentBody}.jpg`}
            webp={`/img/range/range${currentBody}.webp`}
            alt="body image"
          />
        </div>
      </div>
    </div>
  )
}
